import React from "react";
import { IonIcon, IonLabel } from "@ionic/react";

import Portal from "../../../atoms/Portal";
import closeIcon from "../../../assets/icons/close_no_circle.svg";

import styles from "./SurveyRegisterConfirmModal.module.scss";

export interface SurveyRegisterConfirmModalProps {
  isOpen: boolean;
  closeModal: () => void;
  showSurveyAnswerModel: () => void;
}

const SurveyRegisterConfirmModal = (props: SurveyRegisterConfirmModalProps) => {
  const { isOpen, closeModal, showSurveyAnswerModel } = props;
  const backdrop: React.MutableRefObject<HTMLDivElement | null> =
    React.useRef(null);

  React.useEffect(() => {
    if (backdrop.current) {
      backdrop.current.addEventListener("touchstart", e => e.stopPropagation());
      backdrop.current.addEventListener("mousedown", e => e.stopPropagation());
    }
  }, [isOpen]);

  return isOpen ? (
    <Portal>
      <div className={`${styles.modal}`}>
        <div className={`${styles.backdrop}`} ref={backdrop} />
        <div className={styles.slide}>
          <div className={styles.item}>
            <button className={styles.item__button_close} onClick={closeModal}>
              <IonIcon
                icon={closeIcon}
                slot="start"
                className={styles.item__icon_close}
              />
            </button>
          </div>
          <IonLabel className={styles.item__label}>
            何か新しいことに取り組みたいなど、
            <br />
            前向きな気持ちになれていますか？
          </IonLabel>
          <div className={styles.bottom}>
            <button
              className={styles.bottom__button}
              onClick={showSurveyAnswerModel}
            >
              アンケートに回答する
            </button>
          </div>
        </div>
      </div>
    </Portal>
  ) : null;
};

export default SurveyRegisterConfirmModal;
