import React from "react";
import { IonIcon, IonLabel, useIonRouter } from "@ionic/react";

import completeIcon from "../../../assets/icons/complete.svg";
import closeIcon from "../../../assets/icons/close_no_circle.svg";
import Portal from "../../../atoms/Portal";

import styles from "./SurveyAnswerCompleteModal.module.scss";

export interface SurveyAnswerCompleteModalProps {
  isOpen: boolean;
  closeModal: () => void;
}

const SurveyAnswerCompleteModal: React.FC<SurveyAnswerCompleteModalProps> = ({
  isOpen,
  closeModal,
}) => {
  const router = useIonRouter();
  const backdrop: React.MutableRefObject<HTMLDivElement | null> =
    React.useRef(null);

  React.useEffect(() => {
    if (backdrop.current) {
      backdrop.current.addEventListener("touchstart", e => e.stopPropagation());
      backdrop.current.addEventListener("mousedown", e => e.stopPropagation());
    }
  }, [isOpen]);

  const redirectToWillFunc = React.useCallback(() => {
    router.push("/realization");
  }, [router]);

  return isOpen ? (
    <Portal>
      <div className={`${styles.modal}`}>
        <div className={`${styles.backdrop}`} ref={backdrop} />
        <div className={styles.slide}>
          <div className={styles.item}>
            <button className={styles.item__button_close} onClick={closeModal}>
              <IonIcon
                icon={closeIcon}
                slot="start"
                className={styles.item__icon_close}
              />
            </button>
          </div>

          <div className={styles.wrapper}>
            <div className={styles.circleWrapper}>
              <p className={styles.count}>
                <IonIcon
                  icon={completeIcon}
                  slot="start"
                  className={styles.item__icon_complete}
                />
              </p>
            </div>
            <div className={styles.question}>
              <div>
                <IonLabel className={styles.question__label}>
                  入力は以上です。
                  <br />
                  お疲れさまでした！
                </IonLabel>
              </div>
            </div>
            <div className={styles.description}>
              <IonLabel>
                記録して気づいたことがあればストックしておこう。
              </IonLabel>
            </div>
            <div className={styles.actions}>
              <button
                className={styles.actions__button}
                onClick={() => closeModal()}
              >
                閉じる
              </button>
              <button
                className={styles.actions__button}
                onClick={() => {
                  redirectToWillFunc();
                  closeModal();
                }}
              >
                ストックする
              </button>
            </div>
          </div>
        </div>
      </div>
    </Portal>
  ) : null;
};

export default SurveyAnswerCompleteModal;
