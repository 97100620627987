import React from "react";
import { IonIcon, IonInput, IonLabel, IonModal } from "@ionic/react";

import closeIcon from "../../../assets/icons/close_no_circle.svg";
import slashIcon from "../../../assets/icons/slash.svg";
import { IPulseSurveyAnswer, IPulseSurveyQuestion } from "../../../state";

import styles from "./SurveyAnswerModal.module.scss";

export interface SurveyAnswerModalProps {
  isOpen: boolean;
  pulseSurveyQuestions: IPulseSurveyQuestion[];
  pulseSurveyAnswers: IPulseSurveyAnswer[];
  closeModal: () => void;
  currentQuestionIndex: number;
  setCurrentQuestionIndex: (index: number) => void;
  setPulseSurveyAnswers: (
    index: number,
    score: number | undefined,
    reason: string,
  ) => void;
  submitAnswers: () => void;
  openConfirmModal: () => void;
}

const SurveyAnswerModal: React.FC<SurveyAnswerModalProps> = ({
  isOpen,
  pulseSurveyQuestions,
  pulseSurveyAnswers,
  currentQuestionIndex,
  setCurrentQuestionIndex,
  setPulseSurveyAnswers,
  closeModal,
  submitAnswers,
  openConfirmModal,
}) => {
  const getPercentage = (index: number, question_length: number) => {
    return Math.floor((index / question_length) * 100);
  };

  const getCircleStyle = (index: number, question_length: number) => {
    const deg = (360 / 100) * getPercentage(index + 1, question_length);
    if (deg === 360 || deg === 0) {
      return {
        background: `conic-gradient(
          ${deg === 360 ? "#3EBB9D" : "#C2C8D0"} 0deg 360deg
        )`,
      };
    }
    return {
      background: `conic-gradient(
        #3EBB9D 0deg ${deg}deg,
        #C2C8D0 ${deg + 1}deg 360deg
      )`,
    };
  };

  const questionPrev = () => {
    if (currentQuestionIndex === 0) {
      openConfirmModal();
      return;
    }
    setCurrentQuestionIndex(currentQuestionIndex - 1);
  };

  const questionNext = () => {
    if (currentQuestionIndex + 1 === pulseSurveyQuestions.length) {
      submitAnswers();
      return;
    }
    setCurrentQuestionIndex(currentQuestionIndex + 1);
  };

  const getContent = (content: string) => {
    const isIncludeNextLine = content.includes("\n");
    return (
      <>
        {isIncludeNextLine ? (
          <div>
            {content.split("\n").map((string, index) => (
              <span key={index}>
                {string}
                <br />
              </span>
            ))}
          </div>
        ) : (
          <span>{content}</span>
        )}
      </>
    );
  };

  const getScoreArray = (max_score: number) => {
    const numberArray: number[] = [];
    for (let i = 1; i <= max_score; i++) {
      numberArray.push(i);
    }
    return numberArray;
  };

  return (
    <IonModal isOpen={isOpen} className={styles.wrapper}>
      {isOpen && (
        <div className={`${styles.modal}`}>
          <div className={styles.slide}>
            <div className={styles.item}>
              <button
                className={styles.item__button_close}
                onClick={closeModal}
              >
                <IonIcon
                  icon={closeIcon}
                  slot="start"
                  className={styles.item__icon_close}
                />
              </button>
            </div>
            <div className={styles.content}>
              <div className={styles.circleWrapper}>
                <p
                  className={styles.circle}
                  style={getCircleStyle(
                    currentQuestionIndex,
                    pulseSurveyQuestions.length,
                  )}
                />
                <p className={styles.count}>
                  <span className={styles.count__current}>
                    {currentQuestionIndex + 1}
                  </span>
                  <IonIcon
                    icon={slashIcon}
                    slot="start"
                    className={styles.count__slash}
                  />
                  <span className={styles.count__total}>
                    {pulseSurveyQuestions.length}
                  </span>
                </p>
              </div>
              <div className={styles.question}>
                <div>
                  <IonLabel className={styles.question__label}>
                    {getContent(
                      pulseSurveyQuestions[currentQuestionIndex].content,
                    )}
                  </IonLabel>
                </div>
                {currentQuestionIndex === 0 && (
                  <a
                    href="https://www.enageed.jp/release-notes/student/240710"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <IonLabel className={styles.question__help}>
                      このアンケートの目的って？
                    </IonLabel>
                  </a>
                )}
              </div>
              <div className={styles.score}>
                <div className={styles.score__wrapper}>
                  <div className={styles.score__check_group}>
                    {getScoreArray(
                      pulseSurveyQuestions[currentQuestionIndex].max_score,
                    ).map((key, index) => (
                      <div
                        key={index}
                        className={`${styles.score__box} ${
                          pulseSurveyAnswers[currentQuestionIndex].score === key
                            ? styles.score__box_checked
                            : ""
                        }`}
                        onClick={() => {
                          setPulseSurveyAnswers(
                            currentQuestionIndex,
                            key,
                            pulseSurveyAnswers[currentQuestionIndex].reason,
                          );
                        }}
                      >
                        {pulseSurveyAnswers[currentQuestionIndex].score === key
                          ? "✔"
                          : "・"}
                      </div>
                    ))}
                  </div>
                  <div className={styles.score__label_group}>
                    <IonLabel className={styles.score__label}>
                      当てはまらない
                    </IonLabel>
                    <IonLabel className={styles.score__label}>
                      当てはまる
                    </IonLabel>
                  </div>
                </div>
              </div>
              <div className={styles.reason}>
                <IonInput
                  type="text"
                  name="reason"
                  value={pulseSurveyAnswers[currentQuestionIndex].reason}
                  className={styles.reason__input}
                  placeholder="○○だと思ったから。"
                  onIonChange={e => {
                    setPulseSurveyAnswers(
                      currentQuestionIndex,
                      pulseSurveyAnswers[currentQuestionIndex].score,
                      e.detail.value as string,
                    );
                  }}
                />
              </div>
              <div className={styles.actions}>
                <button
                  className={`${styles.actions__button} ${styles.actions__button_first_prev}`}
                  onClick={() => questionPrev()}
                >
                  戻る
                </button>
                <button
                  className={`${styles.actions__button} ${styles.actions__button_first_next}`}
                  onClick={() => questionNext()}
                >
                  次へ
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </IonModal>
  );
};

export default SurveyAnswerModal;
